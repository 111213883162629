import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { DataService } from "src/app/services/data.service";

@Component({
  selector: "app-loading",
  templateUrl: "./loading.component.html",
  styleUrl: "./loading.component.scss",
})
export class LoadingComponent implements OnInit, OnDestroy {
  _loadingSub: Subscription;
  _loading: boolean = false;
  constructor(private _dataService: DataService) {}
  ngOnDestroy(): void {
    if (this._loadingSub) this._loadingSub.unsubscribe();
  }
  ngOnInit(): void {
    this._loadingSub = this._dataService.loading.subscribe((data: boolean) => {
      this._loading = data;
    });
  }
}
