/* src/app/helpers/basic/loading/loading.component.scss */
.loading-body {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3055;
  opacity: 0.5;
  background: #31353d;
}
.loading {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1060;
  text-align: center;
  width: 80px;
  height: 80px;
}
.loading .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #8f8888;
  border-bottom-color: #4467b1;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=loading.component-XUBTG4WF.css.map */
