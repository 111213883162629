import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Navigate } from "@ngxs/router-plugin";
import {
  Actions,
  ofActionErrored,
  ofActionSuccessful,
  Select,
  Store,
} from "@ngxs/store";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { delay } from "rxjs/operators";
import { Bill } from "src/app/interface/bill";
import { FirebaseUser } from "src/app/interface/user";
import { AlertService } from "src/app/services/alert.service";
import { DataService } from "src/app/services/data.service";
import {
  AddToCart,
  ClearCart,
  ReduceToCart,
  UpdateBillDetailQuantity,
} from "src/app/states/actions/user.actions";
import { UIState } from "src/app/states/ui.state";
import { UserState } from "src/app/states/user.state";

@Component({
  selector: "app-mini-cart",
  templateUrl: "./mini-cart.component.html",
  styleUrls: ["./mini-cart.component.less"],
})
export class MiniCartComponent implements OnInit {
  @Select(UserState.items) items: Observable<any>;
  @Select(UserState.cart) cart: Observable<Bill>;
  @Select(UIState.loadingData) loadingData: Observable<any>;
  @Select(UserState.firebaseUser) firebaseUser: Observable<FirebaseUser>;
  @Output() navigateToCart = new EventEmitter<void>();
  @Output() closeCart = new EventEmitter<void>();

  total = new BehaviorSubject({
    total: 0,
    tax: 0,
    delivery: null,
    km: null,
    services: null,
    distanceDelivery: null,
    discount: null,
    preparationTime: 0,
    time: 0,
    closedPlaces: [],
    deliveryMoment: null,
    deliveryTax: null,
    totalDelivery: null,
    taxesList: [],
    freeShipping: false,
    params: null,
  });

  loading = false;
  rxPlaces = [];
  initSubs: Subscription;
  sesionSubs: any;
  register: Subscription;

  constructor(
    private store: Store,
    private actions: Actions,
    private _alertService: AlertService,
    private _dataService: DataService 
  ) {}

  ngOnInit() {
    this.initTotal();
  }

  reduceToCart = (productFeature) => {
    this._dataService.displayLoading()
    this.loading = true;
    this.handler(ReduceToCart);
    this.store.dispatch(new ReduceToCart(productFeature));
  };

  handler = (action, err = "Error de conexión") => {
    let errorSubs;
    let subs;
    errorSubs = this.actions.pipe(ofActionErrored(action)).subscribe(() => {
      this._dataService.displayLoading(false)
      this._alertService.openSnackBar(err, "error");
      subs && subs.unsubscribe();
      errorSubs && errorSubs.unsubscribe();
      this.loading = false;
    });
    subs = this.actions.pipe(ofActionSuccessful(action)).subscribe(() => {
      this._dataService.displayLoading(false)
      subs && subs.unsubscribe();
      errorSubs && errorSubs.unsubscribe();
      this.loading = false;
    });
  };

  editQuantity = (event, detail) => {
    const newQuantity = Number(event.target.value);
    if (newQuantity > 0) {
      this.loading = true;
      this.handler(UpdateBillDetailQuantity);
      this.store.dispatch(new UpdateBillDetailQuantity(newQuantity, detail));
      event.target.value = newQuantity;
    } else {
      this.loading = true;
      this._dataService.displayLoading()
      this.handler(UpdateBillDetailQuantity);
      this.store.dispatch(new UpdateBillDetailQuantity(1, detail));
      event.target.value = 1;
    }
  };

  addMore = (productFeature) => {
    this._dataService.displayLoading()
    this.loading = true;
    this.handler(AddToCart);
    this.store.dispatch(new AddToCart(productFeature));
  };

  getTotalOfDetail = (detail) => {
    return detail.reduce(
      (total, actual) => {
        const {
          Quantity_BillDetail,
          ProductFeature: { ProductPrice },
        } = actual;
        const quantity = Quantity_BillDetail;
        let price = ProductPrice.Price_ProductPrice;
        if (ProductPrice.Discount) {
          const discount =
            (ProductPrice.Price_ProductPrice * ProductPrice.Discount.Quantity) /
            100;
          total.discount += discount * quantity;
          price = ProductPrice.Price_ProductPrice - discount;
        }
        total.total += price * quantity;
        if (ProductPrice.Tariff && ProductPrice.Cabys) {
          const percent = ProductPrice.Cabys.Percent / 100;
          const amountTax = price * percent * quantity;
          total.tax += amountTax;
          const index = total.taxesList.findIndex(
            (tax) => tax.Code === ProductPrice.Cabys.Code
          );
          if (index > -1) {
            total.taxesList[index].Amount += Number(amountTax);
          } else {
            total.taxesList.push({
              ...ProductPrice.Cabys,
              Amount: Number(amountTax),
            });
          }
        }
        return total;
      },
      { total: 0, tax: 0, discount: 0, taxesList: [] }
    );
  };

  initTotal = () => {
    const details = this.store.select(UserState.cartDetail);
    details.pipe(delay(10)).subscribe((xDetails) => {
      if (xDetails) {
        if (xDetails.length === 0) {
          this.store.dispatch(new Navigate(["home"]));
        } else {
          this.calculateDetails(xDetails);
        }
      }
    });
  };

  calculateDetails = (details) => {
    const total = this.total.value;
    const result = this.getTotalOfDetail(details);
    total.total = result.total;
    total.tax = result.tax;
    total.discount = result.discount;
    total.taxesList = result.taxesList.filter((tax) => tax.Amount > 0);
    this.total.next(total);
  };

  close() {
    this.closeCart.emit();
  }

  navigateCart() {
    this.navigateToCart.emit();
    this.store.dispatch(new Navigate(["/cart"]));
  }

  clearCart() {
    this._dataService.displayLoading()
    this.store.dispatch(new ClearCart());
    this.handler(ClearCart)
  }
}
